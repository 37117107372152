/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // Set height and update if page is resized
        var browserwindow;
        $(window).resize(function() {
          browserwindow = $(window).height();
        }).trigger('resize');

        $(window).scroll(function() {
          $('.itemmarker').each(function() {
            var item = $(this),
            scrollTop = $(window).scrollTop(),
            item_offset = item.offset().top - parseInt(item.css('marginTop'), 10) - scrollTop,
            place = item.data('place'),
            lastItem = $('.itemmarker').index(item) == ($('.itemmarker').length - 1),
            atBottomOfPage = scrollTop == ($(document).height() - browserwindow);

            if (item_offset <= 0 || (lastItem && atBottomOfPage)) {
              $('.site-nav li[class="' + place + '"]').addClass('menupurple').siblings().removeClass('menupurple');
            } 
          });
        }).trigger('scroll');

        // Wobble menu selector - START
        var query = $(".site-nav a");

        TweenMax.staggerTo(query, 1.2, {
          css: { left: 0 },
          delay: 2.6, 
          ease: Elastic.easeOut }, 0.1);

        // Wobble menu selector - FINISH

        // ISOTOPE -Packery - START
        $('.isotope').isotope({
          layoutMode: 'packery',
          itemSelector: '.item',
          packery: {
            gutter: 10
          } 
        });
        // ISOTOPE -Packery - END





        // Scroll - START
        var animatesection = {
          init:function(){
            $('.scroll').click(function(e){
              e.preventDefault();
              $('html, body').animate({
                scrollTop: $(this.hash).offset().top  //  px down from top
              }, 250);
            });
          }
        };
        animatesection.init();
        // Scroll - END

        if (!Modernizr.svg) {
          $('img[src$=".svg"]').each(function() {
            //Replaces 'logo.svg' with 'logo.png'.
            $(this).attr('src', $(this).attr('src').replace('.svg', '.png'));
          });
        }
        
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // Kev's code
        $(document).ready(function() {


        // Firms menu selector _START
        $('.thefirms__selections__selection').each(function(){
          $(this).on('click', function() {
            $('.hidepanel').css('display','block');
            $(this).parents('.row').find('.livebtn').removeClass('livebtn').end().end().addClass('livebtn');
            var firm = $(this).parent().index();
            $('.thefirms__selections__firm').eq(firm).show().siblings().hide();
            if (window.innerWidth < 480) {
              $('html, body').animate({
                scrollTop: $('.thefirms__selections__firm').eq(firm).offset().top - 5 //  px down from top
              }, 250); 
            } else {
              $('html, body').animate({
                scrollTop: $('.thefirms__selections__firm').eq(firm).offset().top - 400 //  px down from top
              }, 250);
            }
          });
        });

        // Firms menu selector _FINISH


      });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
